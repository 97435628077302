import { ReactChildren } from './utils';

type Props = {
    children: ReactChildren;
    className?: string;
    direction?: 'top' | 'right' | 'top-left' | 'top-right';
};

export default function TooltipContent({ className, children, direction = 'top'}: Props) {
    const styleCss =
        ' w-max pointer-events-none rounded bg-primary-800 px-3 py-2.5 text-sm font-medium text-white opacity-0 shadow transition-opacity group-hover:opacity-100';

    if (direction === 'right') {
        return (
            <span className={`${className} left-triangle absolute left-[30px] top-3 -translate-y-2/4 ${styleCss}`}>
                {children}
            </span>
        );
    }

    if (direction === 'top-left') {
        return (
            <span className={`${className} bottom-right-triangle absolute left-2/4 -translate-x-[95%] ${styleCss}`}>
            {children}
        </span>
        );
    }

    if (direction === 'top-right') {
        return (
            <span className={`${className} bottom-left-triangle absolute left-2/4 -translate-x-[5%] ${styleCss}`}>
            {children}
        </span>
        );
    }

    return (
        <span className={`${className} bottom-triangle absolute left-2/4 -translate-x-2/4 ${styleCss}`}>
            {children}
        </span>
    );
}
